import React from 'react';
import PropTypes from 'prop-types';
import {merge} from '../../../lib/object';
import {Trans, t} from '@lingui/macro';
import {Button, Form, Input} from 'antd';
import {createFetchManufacturerAdd, createFetchManufacturerEdit} from '../../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../constants/form';
import {Manufacturer} from '../../../constants/propTypesDefinitions';
import SingleFileUpload from '../../general/SingleFileUpload';
const FormItem = Form.Item;

/**
 * @fero
 */

class ManufacturerForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            manufacturer: Manufacturer,
            isEdit: PropTypes.bool.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0)
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                const {isEdit, manufacturer ={}} = values;
                const fetchFunction = isEdit ? createFetchManufacturerEdit() : createFetchManufacturerAdd();
                const checkedValues = isEdit ?
                    merge(formValues, {id: manufacturer.id}) : formValues;
                fetchFunction(checkedValues).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {values: {isEdit, manufacturer = {}}, onClose} = this.props;
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Názov</Trans>}
            >
                {getFieldDecorator('name', {rules: [mandatoryRule], initialValue: manufacturer.name})(
                    <Input ref={node => this.first = node}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Logo</Trans>}
            >
                {getFieldDecorator('logo', {initialValue: manufacturer.logo})(
                    <SingleFileUpload
                        showPreview={true}
                    />
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    {isEdit ? <Trans>Upraviť</Trans> : <Trans>Vytvoriť</Trans>}
                </Button>
            </FormItem>
        </Form>
    }
}

export default Form.create()(ManufacturerForm);