import React from 'react';
import Helmet from '../project/Helmet';
import ManufacturerAdd from './manufacturers/ManufacturerAdd';
import ManufacturersList from './manufacturers/ManufacturersList';
import { t } from '@lingui/macro';

/**
 * @fero
 */

class ManufacturersPage extends React.PureComponent {
    render() {
        return <React.Fragment>
            <Helmet
                title={t`Výrobcovia`}
            />
            <div className="full-size-height d-flex flex-column">
                <div className="pt-2 add-max-width flex-item-static">
                    <ManufacturerAdd/>
                </div>
                <div className="m-3 overflow-auto flex-item-dynamic-1">
                    <ManufacturersList/>
                </div>
            </div>
        </React.Fragment>;
    }

}

export default ManufacturersPage;