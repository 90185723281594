import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import ManufacturerForm from './ManufacturerForm';
import generalFailedPC from '../../fetch/generalFailedPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import Tooltip from '../../general/Tooltip';
import {Manufacturer} from '../../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Button, Icon} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class ManufacturerEdit extends React.PureComponent {
    static propTypes = {
        manufacturer: Manufacturer.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    }.isRequired;

    render() {
        const {manufacturer} = this.props;
        const reloadData = this.props[GLOBAL_DATA.RELOAD_DATA];
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Úprava výrobcu</Trans>}>
                    <Button className="mx-1" icon="edit" size="small"/>
                </Tooltip>
            }
            values={{isEdit: true, manufacturer: manufacturer}}
            onFinishSuccessful={() => {reloadData([GLOBAL_DATA.MANUFACTURERS])}}
            title={<Trans>Úprava výrobcu</Trans>}
            Form={ManufacturerForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť výrobcu`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(ManufacturerEdit);