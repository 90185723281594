import React from 'react';
import PropTypes from 'prop-types';
import {hasPathChanged} from '../../lib/url'
import {Location} from '../../constants/propTypesDefinitions'
import locationHOC from '../locationProvider/locationHOC';

/**
 * Component that serves for displaying lists.
 * values defines values for each item in list. Each value should correspond to a child. So number and order of values
 *      and children should be correspond. Id is mandatory attribute, serves for selecting of items.
 * onItemClick is fired when item is clicked. As first attribute this function receives value, which is on index of item.
 * selectedValueId defines id of selected value.
 * children is prop provided by react and it's all children of component. These children should correspond to in order
 *      and number to values provided to this component.
 * useScrollIntoView is prop that specifies if after change of value(by changing value, not by clicking on list item)
 *      newly selected item should be scrolled into view.
 *
 * @fero
 */

class List extends React.PureComponent {
    static propTypes = {
        values: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.number.isRequired,
                PropTypes.string.isRequired,
            ]).isRequired,
        }).isRequired).isRequired,
        onItemClick: PropTypes.func.isRequired,
        selectedValueId: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
        children: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
        useScrollIntoView: PropTypes.bool,
        location: Location.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
        }
    }

    componentDidUpdate(prevProps) {
        const {useScrollIntoView, location} = this.props;
        const {location: prevLocation} = prevProps;
        const {clicked} = this.state;
        if (!clicked) {
            if (
                (this.selected != null) &&
                (hasPathChanged(prevLocation, location) ||
                (prevProps.values != this.props.values))
            ) {
                if (useScrollIntoView == true) {
                    this.selected.scrollIntoView();
                }

            }
        } else {
            this.setState({
                clicked: false,
            });
        }
    }

    onClickItem = (value) => {
        this.setState({
            clicked: true,
        });
        const {onItemClick} = this.props;
        onItemClick(value);
    };


    indexOfSelectedItem = () => {
        const {selectedValueId, values, alternativeId = 'id'} = this.props;
        if (selectedValueId === undefined)
            return null;

        let index = -1;
        values.forEach((value, i) => {
            if (value[alternativeId] == selectedValueId) {
                index = i;
            }
        });
        return index;
    };

    render() {
        const {children, values} = this.props;
        const selectedItemIndex = this.indexOfSelectedItem();
        return <div className="full-size-height overflow-auto">
            <ul className="list">
                {children.map(((child, index) => {
                    const selected = selectedItemIndex === index;
                    return <li
                        key={index}
                        ref={selected ? node => {
                            this.selected = node
                        } : null}
                        className={"list-item " + (selected ? "list-item-selected" : "")}
                        onClick={() => this.onClickItem(values[index])}
                    >
                        {child}
                    </li>;
                }))}
            </ul>
        </div>;
    }

}

export default locationHOC(List);