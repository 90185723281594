import React from 'react';
import PropTypes from 'prop-types';
import {getDomain} from '../../lib/url';
import {Checkbox, Icon, Upload} from 'antd';
import {UPLOAD_TARGET} from '../../constants/apiEndpoints';
import Select from './Select';
import Image from './Image';
import {Trans, t} from '@lingui/macro';
import {GLOBAL_DATA} from '../../constants/globalData';
import withDataHOC from '../dataProvider/withDataHOC';
import { getInputOnChangeEventChecked } from '../../lib/project';
import InputText from './InputText';


/**
 * @fero
 */

class SingleFileUpload extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        value: PropTypes.string,
        beforeUpload: PropTypes.func,
        onChange: PropTypes.func,
        isProtected: PropTypes.bool,
        showSelect: PropTypes.bool,
        showPreview: PropTypes.bool,
        prefix: PropTypes.string,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
        [GLOBAL_DATA.UPLOAD_FILES]: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            filename: props.value,
            error: null,
            loading: false,
            selectedFiles: [],
            uploadedFiles: [],
            keepName: false,
        }
    }

    static defaultProps = {
        showSelect: false,
        isProtected: false,
        showPreview: true,
    };

    componentDidMount() {
        const uploadedFiles = this.props[GLOBAL_DATA.UPLOAD_FILES];
        if(uploadedFiles == null || uploadedFiles.length == 0)
            this.reload();
    }

    componentDidUpdate(prevProps) {
        const {value} = this.props;
        const {value: prevValue} = prevProps;
        if (value != prevValue) {
            this.setState({
                filename: value,
            })
        }
    }

    /*shouldComponentUpdate(nextProps) {
        const {
            id, value, beforeUpload, onChange, isProtected, showSelect, showPreview,
            [GLOBAL_DATA.FETCH_HANDLER]: fetchHandler
        } = this.props;
        const {
            id: nextId, value: nextValue, beforeUpload: nextBeforeUpload, onChange: nextOnChange,
            isProtected: nextIsProtected, showSelect: nextShowSelect, showPreview: nextShowPreview,
            [GLOBAL_DATA.FETCH_HANDLER]: nextFetchHandler,
        } = nextProps;
        return !(
            this.props != nextProps && value == nextValue && beforeUpload == nextBeforeUpload &&
            onChange == nextOnChange && isProtected == nextIsProtected && showSelect == nextShowSelect &&
            showPreview == nextShowPreview && fetchHandler == nextFetchHandler && id == nextId
        );
    }*/


    reload() {
        const {showSelect} = this.props;
        const reloadData = this.props[GLOBAL_DATA.RELOAD_DATA];
        if (!showSelect)
            return; // no need to load list of public files

        reloadData([GLOBAL_DATA.UPLOAD_FILES]);
    }

    handleInputChange = (value) => {
        const {onChange} = this.props;
        const newFilename = value != null ? value : '';
        this.setState({
            file: null,
            filename: newFilename,
            error: null,
            loading: false
        });

        if (onChange != null)
            onChange(newFilename);
    };

    handleDraggerChange = (info) => {
        const {onChange, isProtected, prefix} = this.props;
        const {keepName} = this.state;

        if (info.file.status === 'uploading') {
            this.setState({file: info.file, loading: true});
            return;
        }

        if (info.file.status === 'done') {
            const formData = new FormData();
            formData.append('file', info.file.originFileObj);
            formData.append('protected', isProtected == true ? 1 : 0);
            formData.append('keep_name', keepName == true ? 1 : 0);
            formData.append('prefix', prefix != null ? prefix : '');

            fetch(getDomain() + UPLOAD_TARGET, {method: 'POST', body: formData, credentials: 'include'})
                .then((response) => {
                    return response.json()
                        .then((json) => {
                            if (json.result != null) {
                                this.setState({
                                    file: info.file,
                                    filename: json.result,
                                    error: null,
                                    loading: false
                                });
                                this.reload();
                                if (onChange != null)
                                    onChange(json.result);
                            }

                            if (json.error != null) {
                                this.setState({
                                    file: null,
                                    error: json.error.message,
                                    loading: false
                                });
                            }
                        });
                })
                .catch((ex) => {
                    this.setState({
                        file: null,
                        error: ex.toString(),
                        loading: false
                    });
                });
        }
    };

    render() {
        const {file, filename, error, loading, keepName} = this.state;
        const {beforeUpload, showSelect, showPreview, isProtected, className,
        [GLOBAL_DATA.UPLOAD_FILES]: uploadedFiles} = this.props;

        return <div className={className}>
            {showSelect ?
                <Select
                    onChange={this.handleInputChange}
                    value={filename != '' ? filename : null}
                    options={uploadedFiles.map(file => {
                        return {
                            value: file.filename,
                            label: file.filename,//maybe add node with small pictures
                        }
                    })}
                    allowClear={true}
                />
                : 
                <InputText
                    value={filename}
                    onChange={this.handleInputChange}
                    allowClear={true}
                />
            }
            <Upload.Dragger
                fileList={file != null ? [file] : []}
                accept="image/*,.pdf,.txt,.doc,.docx,.xls,.xlsx,.eml,.rtf,.msg,.htm,.html"
                beforeUpload={beforeUpload}
                onChange={this.handleDraggerChange}
            >
                <div className="ant-upload-drag-icon mt-0">
                    {filename ?
                        (showPreview ?
                            <Image src={filename} alt="avatar" className="upload-image"/>
                            : <div>
                            <Icon type={'file'}/>
                            <div className="ant-upload-text">{filename}</div>
                        </div>)
                        : <div>
                        <Icon type={loading ? 'loading' : 'plus'}/>
                        <div className="ant-upload-text">Upload</div>
                    </div>
                    }
                    {error}
                </div>
            </Upload.Dragger>
            { isProtected != true ? 
                <Checkbox
                    checked={keepName}
                    onChange={(e) => {
                        const val = getInputOnChangeEventChecked(e);
                        this.setState({keepName: val});
                    }}
                >
                    <Trans>Zachovať pôvodný názov súboru</Trans>
                </Checkbox>
                : null
            }               
        </div>;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA, GLOBAL_DATA.UPLOAD_FILES])(SingleFileUpload);